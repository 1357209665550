import {Notification, NotificationLevel} from "./internal";
import assert from "assert";

export class Notifications {
  public static getDefault(): Notifications {
    if (this.instance === null || this.instance === undefined) {
      this.instance = new Notifications();
    }
    return this.instance;
  }

  private static instance: Notifications;

  protected notifications: Notification[];

  public constructor() {
    this.notifications = [];
  }

  public hasNotification(): boolean {
    return this.notifications.length > 0;
  }

  public getTitles(sortAsc: boolean = false): string[] {
    const titles: string[] = [];
    for (const noti of this.notifications) {
      titles.push(noti.title);
    }
    if (sortAsc) {
      return titles;
    } else {
      return titles.reverse();
    }
  }

  public count(): number {
    return this.notifications.length;
  }

  public first(remove: boolean = false): Notification {
    assert(this.hasNotification());
    const noti = this.notifications[0];
    if (remove) {
      this.notifications = this.notifications.slice(1);
    }
    return noti;
  }

  public notify(title: string,
                body: string,
                dismiss: string = "Dismiss",
                level: NotificationLevel = NotificationLevel.Error,
                detail: string = ""): Notification {
    const noti = new Notification(title, body, dismiss, level, detail);
    this.notifications.push(noti);
    return noti;
  }

  public notifyError(err: Error,
                     dismiss: string = "Dismiss",
                     level: NotificationLevel = NotificationLevel.Error) {
    this.notify(err.name, err.message, dismiss, level, err.stack);
  }

  public clear(): void {
    this.notifications.splice(0);
  }
}
