import {AxiosResponse} from "axios";

export abstract class ApiError extends Error {

}

export class GenericApiError extends ApiError {
  protected static formatString(fmt: string, ...args: any[]) {
    return fmt.replace(/{(\d+)}/g, (match: string, num: number) => {
      return typeof args[num] !== "undefined" ? args[num] : match;
    });
  }

  public constructor(fmt: string, ...args: any[]) {
    super(GenericApiError.formatString(fmt, ...args));
  }
}

export class HTTPResponseError extends ApiError {
  protected primaryCode: number;
  protected codesCovered: number[];

  protected constructor(pc: number, ...cs: number[]) {
    super();
    this.primaryCode = pc;
    cs.push(pc);
    this.codesCovered = cs;
  }
}

export class ClientError extends HTTPResponseError {
}

export class GenericError extends HTTPResponseError {
  public constructor(response: AxiosResponse) {
    super(response.status);
    this.name = `HTTP Error ${response.status}: ${response.statusText}`;
    this.message = response.data.message || "no detail provided";
  }
}

export class ServerError extends HTTPResponseError {
  public constructor(response?: AxiosResponse) {
    super(500);
    this.name = "Server Error";
    if (response) {
      this.message = response.data.message || "unknown error";
    }
  }
}

export class BadRequestError extends ClientError {
  constructor(response?: AxiosResponse) {
    super(400);
    this.name = "Bad Request";
    if (response) {
      this.message = response.data.message || "unknown error";
    }
  }
}

export class ForbiddenError extends ClientError {
  constructor(response?: AxiosResponse) {
    super(403);
    this.name = "Forbidden";
    if (response) {
      this.message = response.data.message || "unknown error";
    }
  }
}

export class NotFoundError extends ClientError {
  public readonly response?: AxiosResponse;
  public readonly data?: any;

  public constructor(message: string | null, response?: AxiosResponse) {
    super(404);
    this.name = "NotFoundError";
    if (message) {
      this.message = message;
    }
    if (response) {
      this.response = response;
      if (response.data) {
        this.data = response.data;
      }
    }
    if (!message && response) {
      if (response.data && "message" in response.data && response.data.message) {
        this.message = response.data.message;
      }
    }
  }
}
