import {IObject} from "@/models";
import {BigIdentity} from "@/dbTypes";
import {EntitySubtype} from "@/models/id/entity";

export interface IGroup extends IObject {
  ueid: BigIdentity;
  gid: BigIdentity;
  name: string;
}

export class Group extends EntitySubtype<IGroup, BigIdentity> {
  public readonly collectionsPrefix = "/groups";
  public readonly primaryKeyFieldNames: Array<keyof IGroup> = ["gid"];

  public get primaryKey(): BigIdentity {
    return this.data.gid;
  }
}
