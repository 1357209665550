import {Config} from "@/config";
import {EIRApi, IdApi, MailerApi} from "./internal";
import {RdApi} from "@/api/rdapi";
import {BoxsoftApi} from "@/api/boxsoft";

export class Api {
  public static getGlobal(): Api {
    if (this.instance === null || this.instance === undefined) {
      this.instance = new Api(Config.getDefault());
    }
    return this.instance;
  }

  // eslint-disable-next-line no-use-before-define
  private static instance: Api;

  public readonly id: IdApi;
  public readonly eir: EIRApi;
  public readonly mailer: MailerApi;
  public readonly rd: RdApi;
  public readonly boxsoft: BoxsoftApi;

  public constructor(protected config: Config) {
    this.id = new IdApi(this, config);
    this.eir = new EIRApi(this, config);
    this.mailer = new MailerApi(this, config);
    this.rd = new RdApi(this, config);
    this.boxsoft = new BoxsoftApi(this, config);
  }
}
