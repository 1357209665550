import {IObject, MiObject} from "@/models";
import {BigIdentity} from "@/dbTypes";
import {IdApi} from "@/api";

export interface IOrganization extends IObject {
  ueid: BigIdentity;
  displayName: string;
  admins: BigIdentity;
  members: BigIdentity;
  profile?: IOrgProfile;
}

export interface IOrgProfile {
  ueid: BigIdentity;
  display: string;
  mail: {
    domains: string[];
  };
  apps: {
    sec: {
      incidentReportURL?: string;
    };
  };
}

export class Organization extends MiObject<IOrganization, BigIdentity, IdApi> {
  public primaryKeyFieldNames: Array<keyof IOrganization> = ["ueid"];
  public collectionsPrefix: string = "/org";

  public get primaryKey(): BigIdentity {
    return this.data.ueid;
  }
}
