<template>
  <div id="app">
    <nav class="navbar is-dark" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <!--<a class="navbar-item" href="https://bulma.io">
          <img src="https://bulma.io/images/bulma-logo.png" width="112" height="28">
        </a>-->

        <a role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false"
           :class="{'is-active':isNavbarActive}" @click="toggleNavbar">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div class="navbar-menu" :class="{'is-active':isNavbarActive}">
        <div class="navbar-start" v-if="isAuth">
          <router-link to="/" class="navbar-item">Home</router-link>
          <template v-for="page in filterPage(sitemap.children,myRoles)">
            <div class="navbar-item has-dropdown is-hoverable" v-if="filterPage(page.children,myRoles).length>0">
              <router-link :to="page.route.path" class="navbar-link">{{ page.getUCName() }}</router-link>
              <div class="navbar-dropdown">
                <NavbarItem v-for="child in filterPage(page.children,myRoles)"
                            :key="child.id"
                            :page="child"></NavbarItem>
              </div>
            </div>
            <router-link :to="page.route.path" class="navbar-item" v-else>{{ page.getUCName() }}</router-link>
          </template>
        </div>
        <div class="navbar-end">
          <div class="navbar-item is-hoverable" v-if="isAuth">
            <router-link to="/messages">
              <FontAwesomeIcon icon="bell"></FontAwesomeIcon>
            </router-link>
          </div>
          <div class="navbar-item has-dropdown is-hoverable" v-if="isAuth">
            <div class="navbar-item" v-if="session.currentOrg===null||session.currentOrg===undefined">Loading
              Organizations...
            </div>
            <template v-else>
              <div class="navbar-link">{{ session.currentOrg.data.displayName }}</div>
              <div class="navbar-dropdown">
                <a class="navbar-item" v-for="org in organizations"
                   @click="()=>orgClicked(org)">{{ org.data.displayName }}</a>
              </div>
            </template>
          </div>
          <div class="navbar-item">
            <div class="buttons">
              <router-link to="/login" v-if="!isAuth" class="button is-link">Login</router-link>
              <router-link to="/logout" v-else class="button is-primary">Logout</router-link>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <router-view/>
    <div class="modal" v-if="notification.hasNotification()" :class="{'is-active': notification.hasNotification()}">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ notification.first().title }}</p>
          <span class="tag is-danger" v-if="notification.count()>1">{{ notification.count() }}</span>&nbsp;
          <button @click="dismissNotification" class="delete" aria-label="close"></button>
        </header>
        <section class="modal-card-body">
          <p v-if="notification.count()>1" class="other-notis">
            And {{ notification.count() - 1 }} others:
            <span class="other-noti" v-for="title in notification.getTitles(true).slice(1)">{{ title }}; </span>
            <a @click="dismissAll">Dismiss All</a>
          </p>
          <p>{{ notification.first().body }}</p>
          <div v-if="notification.first().detail.length>0" class="notification-detail">
            <pre><code>{{ notification.first().detail }}</code></pre>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" v-if="notification.first().actionLabel!==''"
                  @click="()=>notification.first(true).action()">
            {{ notification.first().actionLabel }}
          </button>
          <button class="button is-danger" @click="dismissNotification">
            {{ notification.first().dismiss }}
          </button>
        </footer>
      </div>
    </div>

    <VueLoading :active="loading" backgroundColor="#633"></VueLoading>
  </div>
</template>

<script lang="ts">
import {Component, DefaultView, Watch} from "@/views";
import {Route} from "vue-router";
import {Notification, NotificationLevel} from "@/notification";
import {homepage, Page} from "@/sitemap";
import NavbarItem from "@/components/NavbarItem.vue";
import {Organization} from "@/models/id/organization";
import {Role} from "./models/id/role";

@Component({
  components: {NavbarItem},
})
export default class App extends DefaultView {
  public myRoles: Set<Role> = new Set();
  public isAuth: boolean = false;
  public isNavbarActive: boolean = false;
  public sitemap: Page = homepage;
  public organizations: Organization[] = [];
  // filterHash and filterNonce are updated to, simply just to indicate Vue that role data has changed and update the
  // views
  private filterHash = 0;
  private filterNonce = 0;

  public async refreshPerms() {
    const myRoles = await this.api.id.listMyRoles(this.session.currentOrg!.data.ueid);
    this.myRoles.clear();
    myRoles.forEach((r) => this.myRoles.add(r));
    this.filterNonce = Math.random() * 2147483647;
  }

  @Watch("$route")
  public onRouteChanged(value: Route, oldValue: Route) {
    this.api.id.isAuthenticated().then((isAuth) => {
      this.isAuth = isAuth;
      if (isAuth && this.organizations.length === 0) {
        this.refreshOrgs();
      }
    }).catch((err: Error) => {
      this.notification.notifyError(err);
    });
  }

  public async created() {
    this.filterHash = Math.random() * 2147483647;
    await this.api.id.isAuthenticated().then((isAuth) => {
      if (isAuth) {
        return this.refreshOrgs();
      }
    });
  }

  public async refreshOrgs() {
    const orgs = await this.api.id.listOrganizations();
    this.organizations = orgs;
    if (orgs.length > 0 && (this.session.currentOrg === null || this.session.currentOrg === undefined)) {
      let initOrg = orgs[0];
      const sessionOrg = sessionStorage.getItem("currentOrganization");
      if (sessionOrg) {
        const orgObj = orgs.find((org) => org.data.ueid.toString() === sessionOrg);
        if (orgObj) {
          initOrg = orgObj;
        }
      }
      const profile = await this.api.id.getUserProfile();
      if (profile && profile.preferences.organization) {
        const orgObj = orgs.find((org) => org.data.ueid === profile.preferences.organization);
        if (orgObj) {
          initOrg = orgObj;
          sessionStorage.setItem("currentOrganization", initOrg.data.ueid.toString());
        }
      } else if (profile) {
        this.api.id.updateUserPreferences({organization: initOrg.data.ueid}).then();
      }
      this.session.currentOrg = initOrg;
      this.$forceUpdate();
    }
    await this.refreshPerms();
  }

  public orgClicked(org: Organization) {
    this.session.currentOrg = org;
    sessionStorage.setItem("currentOrganization", org.data.ueid.toString());
    this.api.id.updateUserPreferences({
      organization: org.data.ueid,
    }).finally(() => {
      // this.$forceUpdate();
      this.$router.go(0);
    });
    this.refreshPerms().then();
  }

  public toggleNavbar(): void {
    this.isNavbarActive = !this.isNavbarActive;
  }

  public dismissNotification(): void {
    this.notification.first(true);
  }

  public dismissAll(): void {
    this.notification.clear();
  }

  public messageClass(noti: Notification) {
    switch (noti.level) {
    case NotificationLevel.Error:
      return {"is-danger": true};
    case NotificationLevel.Info:
      return {"is-info": true};
    case NotificationLevel.Warning:
      return {"is-warning": true};
    default:
      return {"is-success": true};
    }
  }

  public filterPage(pages: Page[], myRoles: Set<Role>): Page[] {
    this.filterHash |= this.filterNonce;
    // return only pages that is set to showInNavbar, and if user is a member to any of the defined visibility roles
    return pages.filter((p) => p.route.showInNavbar &&
      (p.route.visibility === undefined || p.route.visibility.find((v) => myRoles.has(v)) !== undefined));
  }
}
</script>

<style lang="scss">
@import "styles/main";

.other-notis {
  font-size: small;
}

.other-noti {
  font-style: italic;
}

.notification-detail {
  pre, code, samp {
    font-size: small;
  }
}

.modal-card-body p {
  margin-top: 1em;
  margin-bottom: 1em;
}
</style>
