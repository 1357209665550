import {IObject, MiObject} from "@/models";
import {BigIdentity} from "@/dbTypes";
import {MailerApi} from "@/api/mailerapi";

interface IBoxMapping extends IObject {
  uid: BigIdentity;
  did: BigIdentity;
  localPart: string;
}

export interface BoxMappingPK {
  did: BigIdentity;
  localPart: string;
}

export interface IUserMapping extends IBoxMapping {
  loginEnabled: boolean;
  mailboxEnabled: boolean;
}

export class UserMapping extends MiObject<IUserMapping, BoxMappingPK, MailerApi> {
  public primaryKeyFieldNames: Array<keyof IUserMapping> = ["did", "localPart"];
  public collectionsPrefix: string = "/users";

  public get primaryKey(): BoxMappingPK {
    return {
      did: this.data.did,
      localPart: this.data.localPart,
    };
  }
}

export interface IForwardingMapping extends IBoxMapping {
  destination: string;
}

interface ForwardingMappingPK extends BoxMappingPK {
  destination: string;
}

export class ForwardingMapping extends MiObject<IForwardingMapping, ForwardingMappingPK, MailerApi> {
  public primaryKeyFieldNames: Array<keyof IForwardingMapping> = ["did", "localPart", "destination"];
  public collectionsPrefix: string = "/forwardings";

  public get primaryKey(): ForwardingMappingPK {
    return {
      did: this.data.did,
      localPart: this.data.localPart,
      destination: this.data.destination,
    };
  }
}

export interface ISendAsMapping extends IBoxMapping {
  sendAsLocalPart: string;
  sendAsDid: number;
}

interface SendAsMappingPK extends BoxMappingPK {
  sendAsLocalPart: string;
  sendAsDid: number;
}

export class SendAsMapping extends MiObject<ISendAsMapping, SendAsMappingPK, MailerApi> {
  public primaryKeyFieldNames: Array<keyof ISendAsMapping> = ["did", "localPart", "sendAsLocalPart", "sendAsDid"];
  public collectionsPrefix: string = "/forwardings";

  public get primaryKey(): SendAsMappingPK {
    return {
      did: this.data.did,
      localPart: this.data.localPart,
      sendAsDid: this.data.sendAsDid,
      sendAsLocalPart: this.data.sendAsLocalPart,
    };
  }
}
