import {Config} from "@/config";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {
  Api,
  BadRequestError,
  ClientError,
  ForbiddenError,
  GenericError,
  Message,
  NotFoundError,
  ServerError,
} from "./internal";
import {Pagination} from "@/api/pagination";
import JSONbig from "json-bigint";

export interface CancelToken {
  cancel?(): void;
}

export interface RequestConfig<T1 = any, T2 = T1> extends AxiosRequestConfig {
  authenticate?: boolean;
  token?: string;
  limit?: number;
  offset?: number;
  query?: Map<string, any>;
  // transform is only used for transforming individual items in a pagination request
  transform?: (val: T1) => T2;
}

export type VarargsConsumer<T, R = void> = (...items: T[]) => R;

export function query2string(query: Map<string, any>): string {
  const entries = [];
  for (const [key, val] of query) {
    entries.push(encodeURIComponent(key) + "=" + encodeURIComponent(val));
  }
  return entries.join("&");
}

const defaultRequestConfig: RequestConfig = {
  authenticate: true,
  transformResponse(data: string) {
    if (data.length === 0 || data.trim().length === 0) {
      return null;
    }
    try {
      return JSONbig.parse(data);
    } catch (e: any) {
      if (e.name === "SyntaxError") {
        return data;
      }
      throw e;
    }
  },
  transformRequest(data: any, headers?: any) {
    if (headers) {
      headers["Content-Type"] = "application/json; charset=utf-8";
    }
    return JSONbig.stringify(data);
  },
};

type AxiosRequestFunc = <T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig)
  => Promise<R>;

export abstract class RootApi {
  protected static ensureBigInt(num: number | string | bigint): bigint {
    if (typeof num === "bigint") {
      return num;
    } else {
      return BigInt(num);
    }
  }

  protected constructor(protected api: Api,
                        protected config: Config) {
  }

  /**
   * HTTP Get
   * @param url plain URL, queries should be in config param
   * @param config
   * @deprecated use get2 instead
   */
  public async get<T = any>(url: string, config?: RequestConfig): Promise<Message<T>> {
    return this.request((rUrl, _, rConfig) => axios.get(rUrl, rConfig), url, config);
  }

  public async get2<T = any>(url: string, config?: RequestConfig): Promise<T> {
    return await this.get<T>(url, config) as unknown as T;
  }

  public async delete<T = any>(url: string, config?: RequestConfig): Promise<Message<T>> {
    return this.request((rUrl, _, rConfig) => axios.delete(rUrl, rConfig), url, config);
  }

  public async post<T = any>(url: string, config?: RequestConfig): Promise<Message<T>> {
    return this.request(axios.post, url, config);
  }

  public async post2<T = any>(url: string, config?: RequestConfig): Promise<T> {
    return this.request2(axios.post, url, config);
  }

  /**
   * HTTP Put
   * @param url plain URL, queries should be in config param
   * @param config
   * @deprecated use put2 instead
   */
  public async put<T = any>(url: string, config?: RequestConfig): Promise<Message<T>> {
    return this.request(axios.put, url, config);
  }

  public async put2<T = any>(url: string, config?: RequestConfig): Promise<T> {
    return this.request2(axios.put, url, config);
  }

  /**
   * HTTP Patch
   * @param url plain URL, queries should be in config param
   * @param config
   * @deprecated use patch2 instead
   */
  public async patch<T = any>(url: string, config?: RequestConfig): Promise<Message<T>> {
    return this.request(axios.patch, url, config);
  }

  public async patch2<T = any>(url: string, config?: RequestConfig): Promise<T> {
    return this.request2(axios.patch, url, config);
  }

  public async getAllPaginated<T1 = any, T2 = T1>(url: string,
                                                  consumer?: VarargsConsumer<T2>,
                                                  config?: RequestConfig<T1, T2>,
                                                  cancelToken?: CancelToken): Promise<T2[]> {
    if (cancelToken && cancelToken.cancel) {
      cancelToken.cancel();
    }
    const isCancelled = {isCancelled: false};
    if (cancelToken) {
      cancelToken.cancel = () => isCancelled.isCancelled = true;
    }
    let limit = 32;
    let offset = 0;
    let query: Map<string, any> = new Map<string, any>();
    if (config) {
      if (config.limit) {
        limit = config.limit;
      }
      if (config.offset) {
        offset = config.offset;
      }
      if (config.query) {
        query = config.query;
      }
    }
    query.set("paginate", true);
    query.set("limit", limit);
    query.set("offset", offset);
    if (config?.params) {
      for (const k of Object.keys(config.params)) {
        query.set(k, config.params[k]);
      }
      delete (config.params);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await this.getAllPaginatedHelper<T1, T2>(url + "?" + query2string(query), consumer, config, isCancelled);
  }

  protected async getAllPaginatedHelper<T1 extends T2[], T2>(url: string,
                                                             consumer: VarargsConsumer<T2>,
                                                             config: RequestConfig,
                                                             isCancelled: { isCancelled: boolean }): Promise<T2[]> {
    const page = await this.get<Pagination<T1>>(url, config) as unknown as Message<T1> | T1;
    let t2Items: T2[];
    let pd: Pagination<T1>;
    if ((page as any).data) {
      pd = (page as any).data;
    } else {
      pd = page as any;
    }

    if (config && config.transform) {
      t2Items = pd.items.map(config.transform);
    } else {
      // TODO if T1 !instanceof T2 AND !config.transform throw error
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      t2Items = pd.items;
    }
    if (t2Items === null) {
      t2Items = [];
    }
    if ((pd.after.trim().length > 0) && !isCancelled.isCancelled) {
      const wait = this.getAllPaginatedHelper<T1, T2>(pd.after, consumer, config, isCancelled);
      if (consumer) {
        consumer(...t2Items);
      }
      return t2Items.concat(await wait);
    } else {
      if (consumer) {
        consumer(...t2Items);
      }
      return t2Items;
    }
  }

  protected async request<T = any>(method: AxiosRequestFunc, url: string, config?: RequestConfig): Promise<Message<T>> {
    const response = await this.requestHelper<Message<T>>(method, url, config);
    const msg = response.data;
    if (msg) {
      return msg;
    }
    return {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      data: null,
      message: response.statusText,
      status: response.status,
    };
  }

  protected async request2<T = any>(method: AxiosRequestFunc, url: string, config?: RequestConfig): Promise<T> {
    return (await this.requestHelper<T>(method, url, config)).data;
  }

  private async requestHelper<T = any>(method: AxiosRequestFunc, url: string, config?: RequestConfig): Promise<AxiosResponse<T>> {
    try {
      const myConfig = Object.assign({
        baseURL: this.config.rootApi,
      }, defaultRequestConfig, config);
      // transform is used for pagination only
      /* if (myConfig.transform) {
        myConfig.transformResponse = myConfig.transform;
      } */
      if (myConfig.authenticate || (myConfig.token !== undefined && myConfig.token !== null)) {
        const token = myConfig.token || this.api.id.getToken();
        if (token === null) {
          // not authenticated
          // TODO proper error handling
          throw new Error("cannot perform an authenticated request while unauthenticated");
        }
        myConfig.headers = Object.assign({}, {
          Authorization: "Bearer " + token,
        }, myConfig.headers);
      }
      let data = null;
      if (myConfig.data) {
        data = myConfig.data;
      }
      return await method(url, data, myConfig);
    } catch (err: any) {
      if (err.response) {
        if (err.response.status === 404) {
          throw new NotFoundError(null, err.response);
        } else if (err.response.status === 500) {
          throw new ServerError(err.response);
        } else if (err.response.status === 400) {
          throw new BadRequestError(err.response);
        } else if (err.response.status === 403) {
          throw new ForbiddenError(err.response);
        } else {
          throw new GenericError(err.response);
        }
      } else if (err.request) {
        // nothing
      }
      // TODO properly handle errors
      // tslint:disable no-console
      console.error(err);
      throw err;
    }
  }
}
