import {BigIdentity} from "@/dbTypes";
import {IObject, MiObject, StringCompatible} from "@/models";
import {IdApi} from "@/api";

export interface IEntity extends IObject {
  ueid: BigIdentity;
  displayName: string;
  type: string;
}

export class Entity extends MiObject<IEntity, BigIdentity, IdApi> {
  public primaryKeyFieldNames: Array<keyof IEntity> = ["ueid"];
  public collectionsPrefix: string = "/entities/";

  public get primaryKey(): bigint | number {
    return this.data.ueid;
  }
}

export interface IEntitySubtype extends IObject {
  ueid: BigIdentity;
}

export abstract class EntitySubtype<I extends IEntitySubtype, K extends StringCompatible> extends MiObject<I, K, IdApi> {
  public displayName: string = "undefined";
  public entityType: string = "undefined";

  public async getEntity(force: boolean = false) {
    const res = await this.api.getEntity(this.data.ueid, force);
    this.displayName = res.data.displayName;
    this.entityType = res.data.type;
  }
}
