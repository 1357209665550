export enum Role {
  SuperAdministrators = "superAdmin",
  Administrators = "administrators",
  Members = "members",
  RemoteAccess = "remoteAccess",

  LogReviewers = "secLogReviewers",

  MIOnlineAdministrators = "mioAdministrators",
  MIOViewers = "mioViewers",
  MIOAuthors = "mioAuthors",
  MIOManagers = "mioManagers",

  KMUsers = "kmUsers",
  KMManagers = "kmManagers",

  ASAdministrators = "asAdministrators",
  ASApprovers = "asApprovers",
  ASAuthors = "asAuthors",
  ASManagers = "asManagers",
  ASIT = "asIT",

  GINTAdministrators = "gintAdministrators",
  Procurement = "procurement",
  PurchaseWatchers = "purchaseWatchers",

  JOBOrderTHS = "joborderths",

  RDRfq = "rdRfq",

  AssetAdministrators = "assetAdministrators",
  AssetAuthors = "assetASAuthors",
  AssetViewers = "assetViewers",

  PurchaseAdministrators = "purchaseAdministrators",
}

// keep in sync with llce server: llce/models/permission/enum.go
