import {IObject, MiObject} from "@/models";
import {BigIdentity} from "@/dbTypes";
import {IdApi} from "@/api";

export interface IName extends IObject {
  ueid: BigIdentity;
  language: string;
  display: string;
  title?: string;
  prefix?: string;
  firstname?: string;
  middlename?: string;
  lastname?: string;
  suffix?: string;
  nickname?: string;
}

export interface LangId {
  ueid: BigIdentity;
  language: string;
}

export class Name extends MiObject<IName, LangId, IdApi> {
  public primaryKeyFieldNames: Array<keyof IName> = ["ueid", "language"];
  public collectionsPrefix: string = "???"; // TODO

  public get primaryKey(): LangId {
    return {
      ueid: this.data.ueid,
      language: this.data.language,
    };
  }
}
