function float2byteArray(val: number | bigint, len: number): number[] {
  const bytes: number[] = [];
  if (typeof val === "number") {
    while (--len >= 0) {
      bytes[len] = val % 0x100;
      val = Math.floor(val / 0x100);
    }
  } else {
    while (--len >= 0) {
      bytes[len] = Number((val as bigint) % BigInt(0x100));
      val = Math.floor(Number((val as bigint) / BigInt(0x100)));
    }
  }
  return bytes;
}

export function long2ip(long: number | bigint): string {
  return float2byteArray(long, 4).join(".");
}
