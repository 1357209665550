import {RootApi, VarargsConsumer} from "@/api/rootapi";
import {Api} from "@/api/api";
import {Config} from "@/config";
import {ILegacyDocInfo} from "@/models/rd/legacydocs";
import {S3FileUpload} from "@/views/wfpc/form/miFormSchema/v1";
import axios from "axios";
import {BigIdentity} from "@/dbTypes";

export class RdApi extends RootApi {
  public static readonly PATH_PREFIX: string = "/rd";

  public constructor(api: Api, config: Config) {
    super(api, config);
  }

  public async listLegacyDocs(org: BigIdentity, consumer?: VarargsConsumer<ILegacyDocInfo>): Promise<ILegacyDocInfo[]> {
    return this.getAllPaginated<ILegacyDocInfo>(RdApi.PATH_PREFIX + "/mi/legacy/", consumer, {
      limit: 512,
      params: {org},
    });
  }

  public async listLegacyArts(org: BigIdentity, consumer?: VarargsConsumer<ILegacyDocInfo>): Promise<ILegacyDocInfo[]> {
    return this.getAllPaginated<ILegacyDocInfo>(RdApi.PATH_PREFIX + "/mi/legacy_arts/", consumer, {
      limit: 512,
      params: {org},
    });
  }

  public async s3upload(model: S3FileUpload, field: string, parentType: string, idPromise: Promise<string>): Promise<void> {
    if (model.file) {
      const res = await this.api.rd.post2<{ objectName: string, uploadURL: string }>("/rd/graphics/", {
        data: {
          contentLength: model.file.size,
          friendlyName: model.file.name,
          contentType: model.file.type,
          // TODO digest:"",
        },
      });
      await axios.put(res.uploadURL, model.file, {
        headers: {
          "Content-Type": model.file.type,
        },
      });
      model.name = res.objectName;

      // this must run after the parent document has been saved, and so must not be waited upon
      // AGAIN, DO NOT AWAIT HERE
      idPromise.then((id) => this.patch(`/rd/graphics/${model.name}`, {
        data: {
          usage: {
            type: parentType,
            parent: id,
            field,
          },
        },
      })).then();
    }
  }
}
