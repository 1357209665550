import Vue from "vue";
import Router, {RawLocation, Route, RouteConfig} from "vue-router";
import {Api} from "@/api/.";
import assert from "assert";
import {Notifications} from "@/notification";
import {homepage, Page} from "@/sitemap";
import {toTitleCase} from "@/utils";

Vue.use(Router);

type RouterNextFunc<V extends Vue = Vue> = (to?: RawLocation | false | ((vm: V) => any) | void) => void;

// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// component: () => import( ...view path... ),

function requireAuth(to: Route, from: Route, next: RouterNextFunc): any {
  Api.getGlobal().id.isAuthenticated().then((isAuth) => {
    if (isAuth) {
      next();
    } else {
      next({
        path: "/login",
        query: {
          redirect: to.fullPath,
        },
      });
      Notifications.getDefault().notify(
        "Not Authenticated",
        "The page you requested requires authentication. Please log in.");
    }
  }).catch((err: Error) => {
    Notifications.getDefault().notifyError(err);
  });
}

function doLogout(to: Route, from: Route, next: RouterNextFunc): any {
  Api.getGlobal().id.logout();
  Api.getGlobal().id.isAuthenticated().then((isAuth) => {
    assert(!isAuth);
    // eslint-disable-next-line n/handle-callback-err,@typescript-eslint/no-unused-vars
  }).catch((err) => {
    // TODO
  });
  next("/login");
}

const routes: RouteConfig[] = [];

function walkSitemap(page: Page): void {
  const rc: RouteConfig = Object.assign({}, page.route);
  if (page.route.requiresAuth) {
    rc.beforeEnter = requireAuth;
  }
  routes.push(rc);
  for (const child of page.children) {
    walkSitemap(child);
  }
}

walkSitemap(homepage);

routes.push({
  path: "/logout",
  name: "logout",
  beforeEnter: doLogout,
});

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const titleQ = to.query.title;
  const title = [];
  if (typeof titleQ === "string") {
    if (titleQ.length > 0) {
      title.push(titleQ);
    }
  } else if (titleQ && titleQ.length > 0 && titleQ[0]!.length > 0) {
    title.push(titleQ[0]!);
  }
  if (to.name) {
    title.push(toTitleCase(to.name));
  }
  title.push("THSG LLCE");
  document.title = title.join(" | ");
  next();
});

export default router;
