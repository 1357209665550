import {RootApi} from "@/api/rootapi";
import {Api} from "@/api/api";
import {Config} from "@/config";
import {BigIdentity} from "@/dbTypes";
import {CustomersGrouped} from "@/models/boxsoft/customer";
import moment from "moment";

export class BoxsoftApi extends RootApi {
  public static readonly PATH_PREFIX: string = "/boxsoft";

  public constructor(root: Api, config: Config) {
    super(root, config);
  }

  public async listCustomers(org: BigIdentity): Promise<CustomersGrouped> {
    return await this.get2<CustomersGrouped>(BoxsoftApi.PATH_PREFIX + "/customers", {params: {org}}).then((res) => {
      for (const nr of Object.keys(res)) {
        res[nr].forEach((c) => {
          c.created = moment(c.created);
          c.updated = moment(c.updated);
          return c;
        });
      }
      return res;
    });
  }
}
