import {dom, library} from "@fortawesome/fontawesome-svg-core";
import {faConfluence, faUncharted} from "@fortawesome/free-brands-svg-icons";
import {
  faAngleDown, faArrowDown, faArrowLeft, faArrowRight, faArrowUp, faBell, faBuilding, faCloud,
  faFilter, faGlobe, faGlobeAsia, faHammer, faMailBulk, faMicroscope, faNetworkWired, faRandom,
  faSave, faSearch, faSort, faSortDown, faSortUp, faTimes, faToolbox, faTools, faTrashAlt,
  faUpload, faUser, faUsers, faUserSecret, faCheck, faSyncAlt, faKey, faCheckSquare, faSquare,
  faPencilAlt, faList, faEdit, faMagnifyingGlassPlus, faMagnifyingGlassMinus, faPlus, faLink,
  faFlask, faArrowUpRightFromSquare, faMapLocationDot, faEye, faBan, faBars, faBug, faCloudArrowUp,
  faFloppyDisk, faAngleDoubleRight, faFileCirclePlus, faCaretDown, faCircleInfo, faComputer,
  faGears, faDiceD6, faIdCard, faCircleXmark, faGear, faFilePdf, faRulerCombined,
  faLayerGroup, faCubes, faMinus, faPaperclip, faScroll, faBraille, faMagnifyingGlass,
  faFile, faFileLines, faFileImage, faFileZipper, faFileWord, faFileExcel,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import Vue from "vue";

// already thought of moving icon loading into individual files, but fas has like 4k icons and we're loading <100,
// so much easier to globally manage them in this one file
library.add(
  faAngleDown, faArrowDown, faArrowLeft, faArrowRight, faArrowUp, faBell, faBuilding, faCloud,
  faFilter, faGlobe, faGlobeAsia, faHammer, faMailBulk, faMicroscope, faNetworkWired, faRandom,
  faSave, faSearch, faSort, faSortDown, faSortUp, faTimes, faToolbox, faTools, faTrashAlt,
  faUpload, faUser, faUsers, faUserSecret, faCheck, faSyncAlt, faKey, faCheckSquare, faSquare,
  faPencilAlt, faList, faEdit, faMagnifyingGlassPlus, faMagnifyingGlassMinus, faPlus, faLink,
  faFlask, faArrowUpRightFromSquare, faMapLocationDot, faEye, faBan, faBars, faBug, faCloudArrowUp,
  faFloppyDisk, faAngleDoubleRight, faFileCirclePlus, faCaretDown, faCircleInfo, faComputer,
  faUncharted, faGears, faDiceD6, faIdCard, faCircleXmark, faGear, faFilePdf, faRulerCombined,
  faLayerGroup, faCubes, faMinus, faPaperclip, faScroll, faConfluence, faBraille, faMagnifyingGlass,
  faFile, faFileLines, faFileImage, faFileZipper, faFileWord, faFileExcel, faEnvelope,
);
dom.watch();
Vue.component("FontAwesomeIcon", FontAwesomeIcon);
