import {RootApi} from "@/api/rootapi";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IObject {
}

export abstract class MiObject<T extends IObject,
  PK extends StringCompatible = number | bigint,
  A extends RootApi = RootApi>
implements IMiObject<T, PK> {
  public readonly data!: T;

  protected modified: boolean = false;
  protected modifiedFields: Set<keyof T> = new Set<keyof T>();
  protected original!: T;
  protected current!: T;

  public constructor(private raw: T, protected api: A) {
    this.readFromRaw(raw);
  }

  public abstract get primaryKeyFieldNames(): Array<keyof T>;

  public abstract get primaryKey(): PK;

  public abstract get collectionsPrefix(): string;

  public isModified(): boolean {
    return this.modified;
  }

  public async create(): Promise<PK> {
    const res = await this.api.post<T>(this.collectionsPrefix, {data: this.data});
    this.readFromRaw(res.data);
    return this.primaryKey;
  }

  public async read(): Promise<T> {
    const res = await this.api.get<T>(this.collectionsPrefix + this.primaryKey.toString());
    this.readFromRaw(res.data);
    return res.data;
  }

  public async update(patch: boolean = false): Promise<T> {
    let res;
    if (patch) {
      const changes = this.getChanges();
      if (!this.isModified() || Object.entries(changes).length === 0) {
        // TODO no changes
        // tslint:disable no-console
        console.error("no change - no patching necessary");
        return this.raw;
      }
      // BETA
      res = await this.api.patch<T>(this.collectionsPrefix + this.primaryKey.toString(),
                                    {data: changes});
    } else {
      // TODO this is a temporary fix, because the patch branch does not detect modifications on a non-existing field
      const contents: { [key: string]: any } = Object.assign({}, this.data);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Object.keys(this.raw).forEach((k) => contents[k] = this.data[k]);
      res = await this.api.put<T>(this.collectionsPrefix + "/" + this.primaryKey.toString(),
                                  {data: contents});
    }
    this.readFromRaw(res.data);
    return res.data;
  }

  public async delete(): Promise<void> {
    const res = await this.api.delete(this.collectionsPrefix + this.primaryKey.toString());
    // TODO destroy and GC this??
  }

  protected readFromRaw(raw: T) {
    this.raw = raw;
    this.original = Object.assign({}, raw);
    this.current = Object.assign({}, raw);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.data = {};
    for (const propName of Object.getOwnPropertyNames(raw)) {
      const rawProp = Object.getOwnPropertyDescriptor(raw, propName);
      if (rawProp && rawProp.enumerable) {
        Object.defineProperty(this.data, propName, {
          configurable: rawProp.configurable,
          /// / since we have set(), so no writable attrib
          // writable: rawProp.writable,
          get: () => {
            return this.current[propName as keyof T];
          },
          set: (v) => {
            this.current[propName as keyof T] = v;
            this.modified = true;
            this.modifiedFields.add(propName as keyof T);
          },
        });
      }
    }
    this.modified = false;
    this.modifiedFields.clear();
  }

  protected getChanges() {
    const changes: any = {};
    for (const key of this.modifiedFields) {
      if (this.data[key] !== this.original[key]) {
        changes[key] = this.data[key];
      }
    }
    return changes;
  }
}

export interface StringCompatible {
  toString(...args: any[]): string;
}

export interface IMiObject<T extends IObject, PK> {
  primaryKey: PK;
  primaryKeyFieldNames: Array<keyof T>;
  collectionsPrefix: string;
}
