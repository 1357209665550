export class Config {
  public static getDefault(clone: boolean = true): Config {
    if (this.instance == null) {
      this.createDefaultInstance();
    }
    if (clone) {
      return Object.assign({}, this.instance);
    } else {
      return this.instance;
    }
  }

  private static instance: Config;

  private static createDefaultInstance(): void {
    if (this.instance != null) {
      // TODO redefining default instance
      // warn pls
    }
    const config = new Config();
    config.rootApi = process.env.VUE_APP_ROOT_API || "http://localhost:8080";

    this.instance = config;
  }

  public rootApi!: string;
}
