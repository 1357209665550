import {long2ip} from "@/models/eir/utils";

export interface EirIp4SubnetResponse {
  subnet: number;
  mask: number;
  owner: number | bigint;
  purpose: string;
  status: string;
  i6subnet?: number;
}

export enum EirSubnetStatus {
  Free = "Free",
  Assigned = "Assigned",
  Reserved = "Reserved",
  Locked = "Locked",
}

export interface SubnetRequest {
  subnet: string;
  mask: number;
  purpose: string;
  static: boolean;
  poolMask: number;
  ipv6: boolean;
  organization: string;
}

export class EirSubnet {
  public isEditing: boolean = false;

  // TODO proper MI object inheritance
  public data: EirIp4SubnetResponse;
  protected changed: boolean = false;

  public constructor(res: EirIp4SubnetResponse) {
    this.data = res;
  }

  public get isChanged(): boolean {
    return this.changed;
  }

  public get i4subnet(): string {
    return long2ip(this.data.subnet);
  }

  public get i6subnet(): string | null {
    if (this.data.i6subnet) {
      return ("0000" + this.data.i6subnet.toString(16)).slice(-4);
    } else {
      return null;
    }
  }

  public get mask(): number {
    return this.data.mask;
  }

  public get maskLong(): string {
    const bitmask = 0xffffffff + 1 - Math.pow(2, 32 - this.data.mask);
    return long2ip(bitmask);
  }

  public get ownerUeid(): bigint | number {
    return this.data.owner;
  }

  public get purpose(): string {
    return this.data.purpose;
  }

  public set purpose(val: string) {
    if (this.data.purpose !== val) {
      this.changed = true;
    }
    this.data.purpose = val;
  }

  public get status(): EirSubnetStatus {
    return EirSubnetStatus[this.data.status as keyof typeof EirSubnetStatus];
  }
}
