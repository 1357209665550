import {IObject, MiObject} from "@/models";
import {BigIdentity} from "@/dbTypes";
import {MailerApi} from "@/api/mailerapi";

export type DomainMode = "Final" | "Intermediate" | "Disabled";

export interface IDomain extends IObject {
  did: BigIdentity;
  domain: string;
  mode: DomainMode;
  relayTransport?: string;
  massMailbox?: string;
}

export class Domain extends MiObject<IDomain, BigIdentity, MailerApi> {
  public primaryKeyFieldNames: Array<keyof IDomain> = ["did"];
  public collectionsPrefix: string = "/domains";

  get primaryKey(): BigIdentity {
    return this.data.did;
  }
}
