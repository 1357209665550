import {LRUCache} from "lru-cache";
import assert from "assert";

type ObjectGetter<K, V> = (key: K) => Promise<V>;
type IndexMapper<V, I> = (obj: V) => I;
type Indexer<K, V> = (key: K, val: V) => void;

export {
  ObjectGetter,
  IndexMapper,
};

export type CMOptions<K, V, FC = unknown> = LRUCache.Options<K, V, FC> & {
  getter: ObjectGetter<K, V>;
  keyGetter: IndexMapper<V, K>;
  purgeInterval?: number;
};

const defaultCMOptions: CMOptions<any, any, unknown> = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  getter: null,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  keyGetter: null,
  purgeInterval: 5 * 1000,
};

export class CMView<I, K extends NonNullable<unknown>, V extends NonNullable<unknown>, FC = unknown> {
  protected indexMap: Map<I, K> = new Map<I, K>();

  public constructor(protected parent: CacheManager<K, V, FC>,
                     protected getter: ObjectGetter<I, V>,
                     protected indexMapper: IndexMapper<V, I>) {
  }

  public async get(idx: I, force: boolean = false, staleRenew?: (val: V) => any): Promise<V> {
    if (this.indexMap.has(idx)) {
      const key = this.indexMap.get(idx);
      assert(key !== undefined);
      return this.parent.get(key!, force, staleRenew);
    } else {
      const val = await this.getter(idx);
      this.parent.set(val);
      return val;
    }
  }

  public indexer(key: K, val: V): void {
    this.indexMap.set(this.indexMapper(val), key);
  }
}

export class CacheManager<K extends NonNullable<unknown>, V extends NonNullable<unknown>, FC = unknown> {
  protected freshCache: LRUCache<K, V, FC>;
  /**
   * objects in staleCache are still usable, but will force CM to fetch fresh object right away
   */
  protected staleCache: LRUCache<K, V, FC>;
  protected viewIndexer: Array<Indexer<K, V>> = [];
  protected fetchInProgress: Map<K, Promise<V>> = new Map<K, Promise<V>>();

  protected options: CMOptions<K, V, FC>;
  protected hits: number = 0;
  protected misses: number = 0;

  public constructor(options: CMOptions<K, V, FC>, specialStaleOptions?: LRUCache.Options<K, V, FC>) {
    this.options = Object.assign({}, defaultCMOptions, options);
    this.options.getter = this.wrapGetter(this.options.getter);
    this.freshCache = new LRUCache<K, V, FC>(Object.assign({}, this.options, {
      dispose: this.disposeFresh.bind(this),
    }));
    this.staleCache = new LRUCache<K, V, FC>(Object.assign({}, this.options, specialStaleOptions));
    setInterval(() => {
      this.freshCache.purgeStale();
    }, this.options.purgeInterval);
  }

  public newIndex<I>(getter: ObjectGetter<I, V>, indexMapper: IndexMapper<V, I>): CMView<I, K, V, FC> {
    const idx = new CMView<I, K, V, FC>(this, getter, indexMapper);
    this.viewIndexer.push(idx.indexer.bind(idx));
    return idx;
  }

  public async get(key: K, force: boolean = false, staleRenew?: (val: V) => any): Promise<V> {
    if (force) {
      const obj = await this.options.getter(key);
      this.set(obj, key);
      return obj;
    } else {
      let obj = this.freshCache.get(key);
      if (obj === undefined) {
        obj = this.staleCache.get(key);
        const freshObjPromise = this.options.getter(key).then((freshObj) => {
          this.set(freshObj, key);
          if (staleRenew !== undefined) {
            staleRenew(freshObj);
          }
          return freshObj;
        });
        if (obj === undefined) {
          this.misses++;
          return freshObjPromise;
        } else {
          this.hits++;
          return obj;
        }
      } else {
        this.hits++;
        return obj;
      }
    }
  }

  public set(value: V, key?: K): void {
    if (key === undefined) {
      key = this.options.keyGetter(value);
    }
    this.freshCache.set(key!, value);
    this.viewIndexer.forEach((indexer) => {
      indexer(key!, value);
    });
  }

  public setBulk(values: V[], keys?: K[]): void {
    let realKeys: K[];
    // length can exceed but cannot underflow
    if (keys === undefined || keys.length < values.length) {
      realKeys = values.map<K>((v) => this.options.keyGetter(v));
    } else {
      realKeys = keys;
    }
    for (let i = 0; i < values.length; i++) {
      this.freshCache.set(realKeys[i], values[i]);
      this.viewIndexer.forEach((indexer) => {
        indexer(realKeys[i], values[i]);
      });
    }
  }

  protected disposeFresh(key: K, value: V): void {
    this.staleCache.set(key, value);
  }

  protected wrapGetter(get: ObjectGetter<K, V>): ObjectGetter<K, V> {
    return (key) => {
      const fip = this.fetchInProgress.get(key);
      if (fip !== undefined) {
        return fip;
      } else {
        const got = get(key);
        this.fetchInProgress.set(key, got);
        got.finally(() => {
          this.fetchInProgress.delete(key);
        });
        return got;
      }
    };
  }
}
