import {IObject} from "@/models";
import {BigIdentity} from "@/dbTypes";
import {EntitySubtype} from "@/models/id/entity";

export interface IUser extends IObject {
  ueid: BigIdentity;
  uid: BigIdentity;
  username: string;
}

export class User extends EntitySubtype<IUser, BigIdentity> {
  public readonly primaryKeyFieldNames: Array<keyof IUser> = ["uid"];
  public readonly collectionsPrefix: string = "/users";

  public get primaryKey(): BigIdentity {
    return this.data.uid;
  }
}
