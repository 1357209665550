export enum NotificationLevel {
  Info,
  Warning,
  Error,
}

export class Notification {
  public title: string;
  public body: string;
  public dismiss: string;
  public detail: string;
  public level: NotificationLevel;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public action: () => void = () => {
  };

  public actionLabel: string = "";

  public constructor(title: string,
                     body: string,
                     dismiss: string = "Dismiss",
                     level: NotificationLevel = NotificationLevel.Error,
                     detail: string = "") {
    this.title = title;
    this.body = body;
    this.dismiss = dismiss;
    this.level = level;
    this.detail = detail;
  }
}
