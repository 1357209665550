import {Api, RootApi} from "@/api/internal";
import {Config} from "@/config";
import {Domain, IDomain} from "@/models/mailer/domain";
import {IUserMapping, UserMapping} from "@/models/mailer/boxmappings";
import {CacheManager} from "@/cache";
import {BigIdentity} from "@/dbTypes";

export class MailerApi extends RootApi {
  public static readonly PATH_PREFIX: string = "/mailer";

  protected domainCache: CacheManager<bigint, Domain>;

  public constructor(root: Api, config: Config) {
    super(root, config);
    this.domainCache = new CacheManager<bigint, Domain>({
      max: 256,
      ttl: 5 * 1000,
      getter: (did: bigint) => this.get2<IDomain>(MailerApi.PATH_PREFIX + "/domains/" + did.toString())
        .then((res) => new Domain(res, this)),
      keyGetter: (dom: Domain) => MailerApi.ensureBigInt(dom.data.did),
    }, {ttl: 5 * 60 * 1000, ttlAutopurge: true});
  }

  public async getDomains(organization: BigIdentity): Promise<Domain[]> {
    const res = await this.get2<IDomain[]>(MailerApi.PATH_PREFIX + "/domains/", {params: {organization}});
    const doms = res.map((dom) => new Domain(dom, this));
    doms.forEach((dom) => this.domainCache.set(dom));
    return doms;
  }

  public async getDomain(did: BigIdentity, force: boolean = false, staleRenew?: (val: Domain) => any): Promise<Domain> {
    return this.domainCache.get(MailerApi.ensureBigInt(did), force, staleRenew);
  }

  public async getUserMappingsPaginated(org: BigIdentity,
                                        did: BigIdentity | null,
                                        consumer: (...items: UserMapping[]) => void): Promise<UserMapping[]> {
    const query = new Map<string, any>();
    query.set("organization", org);
    if (did !== null) {
      query.set("did", did);
    }
    return await this.getAllPaginated<IUserMapping, UserMapping>(MailerApi.PATH_PREFIX + "/users/",
                                                                 consumer,
                                                                 {
                                                                   query,
                                                                   transform: (iu) => new UserMapping(iu, this),
                                                                 });
  }
}
