<template>
  <div v-if="filterPage(page.children).length>0" class="navbar-item nested dropdown">
    <!--<div class="dropdown-trigger">-->
    <router-link :to="page.route.path"
                 class="dropdown-trigger"
                 aria-haspopup="true"
                 aria-controls="dropdown-menu">
      {{ page.getUCName() }}
      <FontAwesomeIcon icon="angle-down"></FontAwesomeIcon>
    </router-link>
    <!--</div>-->
    <div class="dropdown-menu" role="menu">
      <div class="dropdown-content">
        <NavbarItem v-for="child in page.children"
                    v-if="child.route.showInNavbar"
                    :key="child.id"
                    :page="child"></NavbarItem>
      </div>
    </div>
  </div>
  <router-link v-else :to="page.route.path" class="navbar-item">{{ page.getUCName() }}</router-link>
</template>

<script lang="ts">
import {Component, DefaultView, Prop} from "../views";
import {Page} from "@/sitemap";

@Component({
  name: "NavbarItem",
})
export default class NavbarItem extends DefaultView {
  @Prop()
  private page!: Page;

  private gotoLink(): void {
    this.$router.push(this.page.route.path);
  }

  private filterPage(pages: Page[]): Page[] {
    // return only pages that is set to showInNavbar, !!!and if user is a member to any of the defined visibility roles!!!
    /// !!! not yet implemented
    return pages.filter((p) => p.route.showInNavbar);
  }
}
</script>

<style lang="scss" scoped>
// https://codepen.io/sakthig/pen/RvwVYM?editors=1100
.nested.dropdown {
  &:hover > .dropdown-menu {
    display: block;
  }

  .dropdown-menu {
    top: -15px;
    margin-left: 100%;
  }

  a.dropdown-trigger {
    &:link, &:active, &:visited {
      color: #4a4a4a !important;
    }

    /*&:hover {
      color: #3273dc !important;
    }*/
  }
}

/*
lineheight 1.5
 */
</style>
