export function isNumberValid(val: number | bigint | string | null | undefined): boolean {
  if (val === null || val === undefined) {
    return false;
  }
  if (typeof val === "number") {
    return !isNaN(val);
  } else if (typeof val === "bigint") {
    return true;
  } else {
    return !isNaN(Number(val));
  }
}

export function sandwich(min: number, val: number, max: number): number {
  if (val > max) {
    return max;
  }
  if (val < min) {
    return min;
  }
  return val;
}

const SIPrefixes = "yzafpnµm kMGTPEZY".split(""); // i=8 is 0
export function formatSI(num: bigint | number,
                         numberFormatter: (num: number) => string = (n) => n.toPrecision(3),
                         includesLeftGap: boolean = true): string {
  num = Number(num);
  if (num === 0) {
    return "0";
  }
  const magnitude = Math.log10(num);
  const scale = sandwich(-8, Math.floor(magnitude / 3), SIPrefixes.length - 9);
  const formatted = numberFormatter(num / (1000 ** scale));
  if (scale === 0) {
    return formatted;
  }

  return formatted + (includesLeftGap ? " " : "") + SIPrefixes[scale + 8];
}

const allCaps = ["MI", "THS", "THSG", "API", "SVG", "e-Document", "e-Documents", "ESKO", "XML", "RFQ", "R&D", "to",
  "KM", "IT", "CRM", "SW", "SV", "VM", "DSSM", "TPK", "MultiFileUploadControl"];
const allCapsIndex = allCaps.map((s) => s.toLowerCase());

export function toTitleCase(text: string): string {
  const comps = text.toLowerCase().split(" ");
  for (let i = 0; i < comps.length; ++i) {
    const f = allCapsIndex.indexOf(comps[i]);
    if (f >= 0) {
      comps[i] = allCaps[f];
      continue;
    }
    comps[i] = comps[i].charAt(0).toLocaleUpperCase() + comps[i].slice(1);
  }
  return comps.join(" ");
}

export function ensureNumbers(obj: { [key: string]: string | number }, keys: string[] | null, parser = parseInt) {
  if (keys === null) {
    keys = Object.keys(obj);
  }
  for (const key of keys) {
    const val = obj[key];
    if (typeof val !== "number") {
      obj[key] = parser(val, 10);
    }
  }
}

export function forceNumber(val: string | number): number {
  if (typeof val === "number") {
    return val;
  }
  return parseFloat(val);
}

export function round(v: number, multiple: number): number {
  v /= multiple;
  v = Math.round(v);
  return v * multiple;
}

export function encodeHTMLEntities(text: string): string {
  const obj: HTMLPreElement = document.createElement("pre");
  obj.innerText = text;
  const encoded = obj.innerHTML.replaceAll(/<br>/g, "\n");
  obj.remove();
  return encoded;
}
