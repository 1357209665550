import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Config} from "@/config";
import {Api} from "@/api/.";
import {Notifications} from "@/notification";
import {Organization} from "@/models/id/organization";
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export {Component, Prop, Vue, Watch};

Vue.component("VueLoading", VueLoading);

class Session {
  public currentOrg?: Organization;
}

const globalSession = new Session();

class LoadQueue {
  private loads: Array<Promise<any>> = [];

  public constructor(private view: DefaultView, ...loads: Array<Promise<any>>) {
    this.queue(...loads);
  }

  public queue(...loads: Array<Promise<any>>): this {
    this.loads.push(...loads);
    return this;
  }

  public load() {
    const batch = this.loads.splice(0);
    return this.view.waitLoad(Promise.all(batch)).then();
  }
}

export class DefaultView extends Vue {
  protected api: Api = Api.getGlobal();
  protected appConfig: Config = Config.getDefault();
  protected notification: Notifications = Notifications.getDefault();
  protected session: Session = globalSession;
  protected loading: boolean = false;

  public waitLoad<T>(loader: Promise<T>): Promise<T> {
    this.loading = true;
    return loader.finally(() => this.loading = false);
  }

  protected async waitInit<T>(getter: () => T | undefined, pollingPeriod: number = 50): Promise<T> {
    const res = getter();
    if (res) {
      return res;
    }
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.waitInit(getter, pollingPeriod).then(resolve).catch(reject);
      }, pollingPeriod);
    });
  }

  protected queueLoad(...loads: Array<Promise<any>>): LoadQueue {
    return new LoadQueue(this, ...loads);
  }

  protected getQuery(key: string, fallback?: string): string | undefined {
    const raw = this.$route.query[key];
    if (raw) {
      if (typeof raw === "string") {
        return raw;
      }
      if (raw[0]) {
        return raw[0];
      }
    }
    return fallback;
  }
}
