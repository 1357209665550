import {Config} from "@/config";
import {Api, RootApi, VarargsConsumer} from "./internal";
import {IUser, User} from "@/models/id/user";
import {CacheManager, CMView} from "@/cache";
import * as jwt from "jsonwebtoken";
import {Entity, IEntity} from "@/models/id/entity";
import {BigIdentity} from "@/dbTypes";
import {JwtObject} from "@/api/jwt";
import {IName, Name} from "@/models/id/name";
import {IOrganization, Organization} from "@/models/id/organization";
import {Group, IGroup} from "@/models/id/group";
import {DepartmentTree} from "@/models/id/department";
import {Human} from "@/models/id/human";
import {Role as RoleStr} from "@/models/id/role";

interface LoginRequest {
  username: string;
  password: string;
  reCAPTCHA: string;
}

interface LoginResponse {
  token: string;
}

interface IMembershipReport {
  users: IUser[];
  groups: IGroup[];
  effective: IUser[];
}

interface MembershipReport {
  users: User[];
  groups: Group[];
  effective: User[];
}

export interface IdentityInfo {
  ueid: BigIdentity;
  uid: BigIdentity;
  username: string;
}

interface UserProfile {
  uid: BigIdentity;
  preferences: UserPreferences;
}

interface UserPreferences {
  language?: string;
  organization?: BigIdentity;
}

interface Role {
  ou?: string;
  ouType?: string;
  name?: string;
  dirGroup?: DirGroup;
  dbGroup?: BigIdentity;
}

export interface DirGroup {
  dn: string;
  name: string;
  guid: string;
}

export class IdApi extends RootApi {
  public static readonly PATH_PREFIX: string = "/id";
  public static readonly TOKEN_KEY: string = "jwt_token";

  protected cachedToken?: string;
  protected userCache: CacheManager<bigint, User>;
  protected userByUsername!: CMView<string, bigint, User>;
  protected entityCache: CacheManager<bigint, Entity>;
  protected myIdentity?: IdentityInfo;
  protected organizationsCache?: Organization[];

  public constructor(root: Api, config: Config) {
    super(root, config);
    this.userCache = new CacheManager<bigint, User>({
      max: 1024,
      ttl: 60 * 1000,
      getter: (uid) => this.get<IUser>(IdApi.PATH_PREFIX + "/users/" + uid.toString())
        .then((res) => new User(res.data, this)),
      keyGetter: (user) => IdApi.ensureBigInt(user.data.ueid),
    }, {ttl: 5 * 60 * 1000, ttlAutopurge: true});
    this.entityCache = new CacheManager<bigint, Entity>({
      max: 1024,
      ttl: 60 * 1000,
      getter: (ueid) => this.get<IEntity>(IdApi.PATH_PREFIX + "/entities/" + ueid.toString())
        .then((res) => new Entity(res.data, this)),
      keyGetter: (entity) => IdApi.ensureBigInt(entity.data.ueid),
    }, {ttl: 5 * 60 * 1000, ttlAutopurge: true});
    this.createCacheViews();
  }

  public async login(username: string, password: string, reCAPTCHA: string): Promise<string> {
    /*
    const csrfReq = await this.get("/csrf", {authenticate: false});
    const csrf = csrfReq.data.csrf;
     */
    const request: LoginRequest = {
      username,
      password,
      reCAPTCHA,
    };
    const res = await this.post<LoginResponse>(IdApi.PATH_PREFIX + "/login", {
      authenticate: false,
      data: request,
      // withCredentials: true,
      // headers: {"X-Csrf-Token": csrf},
    });
    if (res.data.token) {
      localStorage.setItem(IdApi.TOKEN_KEY, this.cachedToken = res.data.token);
      return res.data.token;
    } else {
      throw new Error(res.message);
    }
  }

  public logout(): void {
    delete this.cachedToken;
    localStorage.removeItem(IdApi.TOKEN_KEY);
    sessionStorage.removeItem(IdApi.TOKEN_KEY);
    // TODO clear cookies too maybe?
  }

  public getMyIdentity(): IdentityInfo | null {
    if (this.myIdentity === null || this.myIdentity === undefined) {
      const token = this.getToken();
      if (token === null) {
        return null;
      }
      const jwtObj = jwt.decode(token, {complete: true}) as JwtObject | null;
      if (jwtObj === null) {
        return null;
      }
      /* if (jwtObj.payload.sub === undefined || jwtObj.payload.ueid === undefined || jwtObj.payload.uid === undefined) {
        return null;
      } */
      this.myIdentity = {
        ueid: BigInt("0x" + jwtObj.payload.sub),
        uid: BigInt(jwtObj.payload.uid),
        username: jwtObj.payload.username,
      };
    }
    return this.myIdentity;
  }

  public async isAuthenticated(usingServer: boolean = false): Promise<boolean> {
    let token: string | null | undefined = this.cachedToken;
    if (token === undefined || token === null) {
      token = localStorage.getItem(IdApi.TOKEN_KEY);
      if (token === null) {
        token = sessionStorage.getItem(IdApi.TOKEN_KEY);
        if (token === null) {
          return false;
        }
      }
    }
    return this.verifyToken(token, usingServer);
  }

  public async verifyToken(token: string, usingServer: boolean = false): Promise<boolean> {
    // sanity checks
    if (token.length === 0) {
      return false;
    }
    const jwtObj = jwt.decode(token, {complete: true}) as JwtObject | null;
    if (jwtObj === null) {
      return false;
    }
    if (jwtObj.header.typ !== "JWT") {
      return false;
    }
    if (jwtObj.payload.exp !== undefined) {
      const expiry = new Date(jwtObj.payload.exp * 1000);
      if (expiry < new Date()) {
        return false;
      }
    }

    if (usingServer) {
      // TODO verify using server
    }
    return true;
  }

  public getToken(): string | null {
    // must return a valid token, otherwise null
    // TODO rn just get wtv is in storage
    return this.cachedToken || localStorage.getItem(IdApi.TOKEN_KEY) || sessionStorage.getItem(IdApi.TOKEN_KEY);
  }

  public async getEntity(ueid: BigIdentity,
                         force: boolean = false,
                         staleRenew?: (e: Entity) => any): Promise<Entity> {
    const key = IdApi.ensureBigInt(ueid);
    return this.entityCache.get(key, force, staleRenew);
  }

  public async getUser(uid: BigIdentity,
                       force: boolean = false,
                       staleRenew?: (u: User) => any): Promise<User> {
    const key = IdApi.ensureBigInt(uid);
    return this.userCache.get(key, force, staleRenew);
  }

  public async getNames(ueid: BigIdentity): Promise<Name[]> {
    const key = IdApi.ensureBigInt(ueid);
    const ins = await this.get<IName[]>(IdApi.PATH_PREFIX + "/entities/" + key.toString() + "/names");
    return ins.data.map<Name>((i) => new Name(i, this));
  }

  public async getUsers(consumer?: VarargsConsumer<User>): Promise<User[]> {
    return this.getAllPaginated<IUser, User>(
      IdApi.PATH_PREFIX + "/users/",
      (...users) => {
        this.userCache.setBulk(users);
        if (consumer) {
          consumer(...users);
        }
      },
      {
        transform: (iu) => new User(iu, this),
      });
  }

  public async getEntities(consumer?: VarargsConsumer<Entity>): Promise<Entity[]> {
    return this.getAllPaginated<IEntity, Entity>(
      IdApi.PATH_PREFIX + "/entities/",
      (...ents) => {
        this.entityCache.setBulk(ents);
        if (consumer) {
          consumer(...ents);
        }
      },
      {
        transform: (ie) => new Entity(ie, this),
      },
    );
  }

  public async getUserByUsername(username: string,
                                 force: boolean = false,
                                 staleRenew?: (u: User) => any): Promise<User> {
    return this.userByUsername.get(username, force, staleRenew);
  }

  public async getUserProfile(uid: BigIdentity | "me" = "me"): Promise<UserProfile> {
    return (await this.get<UserProfile>(IdApi.PATH_PREFIX + `/users/${uid}/profile`)).data;
  }

  public async updateUserPreferences(update: UserPreferences, uid: BigIdentity | "me" = "me"): Promise<void> {
    await this.patch(IdApi.PATH_PREFIX + `/users/${uid}/preferences`, {data: update});
  }

  public async listOrganizations(cache: boolean = true): Promise<Organization[]> {
    if (cache || !this.organizationsCache) {
      const res = await this.get2<IOrganization[]>("/org/");
      this.organizationsCache = res.map<Organization>((raw) => new Organization(raw, this));
    }
    return this.organizationsCache;
  }

  public async getOrganization(oid: BigIdentity): Promise<Organization> {
    return new Organization((await this.get<IOrganization>(`/org/${oid}`)).data, this);
  }

  public async listDepartments(org: BigIdentity): Promise<DepartmentTree[]> {
    const res = await this.get<DepartmentTree[]>(`/org/${org}/departments`);
    return res.data || [];
  }

  public async listGroups(): Promise<Group[]> {
    const res = await this.get<IGroup[]>(IdApi.PATH_PREFIX + "/groups/");
    return res.data.map((raw) => new Group(raw, this));
  }

  public async getGroup(gid: BigIdentity): Promise<Group> {
    const res = await this.get<IGroup>(IdApi.PATH_PREFIX + "/groups/" + gid.toString());
    return new Group(res.data, this);
  }

  public async getGroupMembers(gid: BigIdentity): Promise<MembershipReport> {
    const res = await this.get<IMembershipReport>(IdApi.PATH_PREFIX + "/groups/" + gid.toString() + "/members");
    return {
      users: res.data.users.map((u) => new User(u, this)),
      groups: res.data.groups.map((g) => new Group(g, this)),
      effective: res.data.effective.map((u) => new User(u, this)),
    };
  }

  public getRole(organization: BigIdentity, roleName: string): Promise<Role> {
    return this.get<Role>(IdApi.PATH_PREFIX + `/roles/${organization}/${roleName}`).then((r) => r.data);
  }

  public saveRole(organization: BigIdentity, roleName: string, role: Role) {
    return this.put(IdApi.PATH_PREFIX + `/roles/${organization}/${roleName}`, {
      data: role,
    });
  }

  protected createCacheViews(): void {
    this.userByUsername = this.userCache.newIndex<string>(
      (username: string) => this.get<IUser>(IdApi.PATH_PREFIX + "/usernames/" + username)
        .then((res) => new User(res.data, this)),
      (user: User) => user.data.username,
    );
  }

  public listRoleMembers(organization: BigIdentity, roleName: string, filter: string): Promise<Human[]> {
    let query = "";
    if (filter !== "") {
      query = "?q=" + encodeURIComponent(filter);
    }
    return this.get2<Human[]>(IdApi.PATH_PREFIX + `/roles/${organization}/${roleName}/members${query}`);
  }

  public listMyRoles(org: BigIdentity): Promise<RoleStr[]> {
    return this.get2(IdApi.PATH_PREFIX + "/users/me/roles", {params: {org}});
  }
}
