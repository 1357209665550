import {Api, RootApi} from "./internal";
import {Config} from "@/config";
import {EirIp4SubnetResponse, EirSubnet, SubnetRequest} from "@/models/eir/subnet/i4subnet";
import {Organization} from "@/models/id/organization";
import {BigIdentity} from "@/dbTypes";

export class EIRApi extends RootApi {
  public static readonly PATH_PREFIX: string = "/eir";

  public constructor(root: Api, config: Config) {
    super(root, config);
  }

  public async getAllSubnets(): Promise<EirSubnet[]> {
    const res = await this.get<EirIp4SubnetResponse[]>(EIRApi.PATH_PREFIX + "/ip4subnet/");
    return res.data.map((rObj) => new EirSubnet(rObj));
  }

  public async getAllSubnetsPaginated(organization: Organization,
                                      consumer: (...subnets: EirSubnet[]) => void): Promise<EirSubnet[]> {
    const query = new Map<string, any>([["oid", organization.data.ueid]]);
    return await this.getAllPaginated<EirIp4SubnetResponse, EirSubnet>(
      EIRApi.PATH_PREFIX + "/ip4subnet/",
      consumer,
      {
        query,
        transform: (res) => new EirSubnet(res),
      });
  }

  public async moveSubnet(subnet: number, dst: BigIdentity): Promise<EirSubnet> {
    const query = await this.patch<EirIp4SubnetResponse>(EIRApi.PATH_PREFIX + "/ip4subnet/" + subnet.toString(), {
      data: {
        organization: dst.toString(),
      },
    });
    return new EirSubnet(query.data);
  }

  public async allocateSubnet(request: SubnetRequest) {
    const res = await this.post<EirIp4SubnetResponse>(EIRApi.PATH_PREFIX + "/ip4subnet/", {data: request});
    return new EirSubnet(res.data);
  }
}
